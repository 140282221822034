"use client"

import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"

import * as css from "./styles.css"

export function AbstractSkeleton({ className }: PropsWithClassName) {
  return (
    <span className={clsx(className, css.AbstractSkeleton)}>
      <span className={css.pseudoParent} />
    </span>
  )
}
