import { useQuery } from "@tanstack/react-query"

import type { AlgoliaProductHit } from "~/lib/algolia/types"
import { ALGOLIA_RECOMMENDATIONS_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { useGetCart } from "~/hooks/useGetCart"
import useLocale from "~/hooks/useLocale"
import { serializeCardCompleteTheLookCard } from "~/components/ui/CartPanel/CompleteTheLookSlider/Card/data/serializer"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { uniq } from "~/utils/uniq"

export function useCompleteTheLookSliderProductCards() {
  const t = useTranslate()
  const locale = useLocale()

  const { cartPayload } = useGetCart()

  const GIDs = uniq(cartPayload?.lines?.map((line) => line?.merchandise?.product?.id)?.filter(Boolean) ?? []) ?? []

  const title = t("cart_complete_the_look")

  return useQuery({
    enabled: Boolean(GIDs?.length),
    queryKey: ["CompleteTheLookSliderProductCards", GIDs],
    queryFn: async () => {
      if (!GIDs?.length) return { title, items: [] }

      try {
        const recommendations = await Promise.all(
          GIDs?.map(async (id) => {
            const response = (await requestNextApiEndpoint(ALGOLIA_RECOMMENDATIONS_PARAMS, {
              queryParams: { gid: id, locale },
            })) as { data: AlgoliaProductHit[] }

            return response?.data ?? []
          })
        )

        const filteredRecommendations = recommendations?.map((recommendationsSet) => {
          // remove recommendations that are already in the cart
          return recommendationsSet?.filter((object) => object?.product?.id && !GIDs.includes(object?.product?.id))
        })

        // set the length of the recommendations to 8 (or 9 if there is an odd amount of products in cart)
        const setLength = Math.ceil(8 / GIDs?.length)

        const trimmedRecommendations = filteredRecommendations?.map((recommendationsSet) => {
          // trim the recommendations to the set length
          return recommendationsSet?.slice(0, setLength)
        })

        return {
          title,
          items:
            trimmedRecommendations
              ?.flat()
              ?.map((object) => serializeCardCompleteTheLookCard(object?.product, locale))
              ?.filter(Boolean) ?? [],
        }
      } catch {
        return { title, items: [] }
      }
    },
    keepPreviousData: true,
  })
}
