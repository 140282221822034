"use client"

import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import InlineCta from "~/components/ui/InlineCta"
import { AbstractSkeleton } from "~/components/abstracts/Skeleton/index.client"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export function CompleteTheLookCardSkeleton({ className }: PropsWithClassName) {
  const t = useTranslate()

  return (
    <div className={clsx(css.Card, className)}>
      <div className={css.image} style={{ position: "relative", aspectRatio: 74 / 110 }}>
        <AbstractSkeleton />
      </div>
      <div className={clsx(css.footer)}>
        <p className={clsx(css.title)} style={{ position: "relative", overflow: "visible" }}>
          <span style={{ opacity: 0 }}>{t("general_loading")}</span>
          <AbstractSkeleton />
        </p>
        <div className={css.price} style={{ position: "relative" }}>
          <span style={{ opacity: 0 }}>{t("general_loading")}</span>
          <AbstractSkeleton />
        </div>
        <div className={css.cta} style={{ position: "relative", overflow: "visible" }}>
          <InlineCta>
            <span style={{ opacity: 0 }}>{t("general_loading")}</span>
            <AbstractSkeleton />
          </InlineCta>
        </div>
      </div>
    </div>
  )
}
