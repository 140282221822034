"use client"

import { createRef, useEffect, useRef } from "react"
import { usePathname, useRouter } from "next/navigation"
import clsx from "clsx"
import Cookies from "js-cookie"
import { sha256 } from "js-sha256"

import type { UnlikelyCart } from "@unlikelystudio/commerce-connector"
import { useGetCustomer, useUpdateCartAttributes } from "@unlikelystudio/react-ecommerce-hooks"

import type { PropsWithClassName } from "~/@types/generics"
import { getIndexName } from "~/lib/algolia/utils/get-index-name"
import { COOKIE_KEYS } from "~/lib/constants"
import getCurrency from "~/lib/get-currency"
import type { I18nLocale } from "~/lib/i18n/types"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { CHECKOUT_CUSTOM_ATTRIBUTES, getProductCustomAttributesValue } from "~/lib/shopify/constants"
import serializePrice from "~/data/price/serializer"
import useAlgoliaQueryId from "~/hooks/useAlgoliaQueryId"
import { useGetCart } from "~/hooks/useGetCart"
import { useGetDraftOrderUrl } from "~/hooks/useGetDraftOrderUrl"
import { useGetMultipassCheckoutUrl } from "~/hooks/useGetMultipassCheckoutUrl"
import useLocale from "~/hooks/useLocale"
import { CompleteTheLookSlider } from "~/components/ui/CartPanel/CompleteTheLookSlider"
import { useCompleteTheLookSliderProductCards } from "~/components/ui/CartPanel/CompleteTheLookSlider/hooks/useCompleteTheLookSliderProductCards"
import LineItem from "~/components/ui/CartPanel/LineItem"
import ReinsurranceSlider from "~/components/ui/CartPanel/ReinsurranceSlider"
import TotalPrice from "~/components/ui/CartPanel/TotalPrice"
import InlineCta from "~/components/ui/InlineCta"
import { getPath } from "~/components/ui/Link/utils/href-resolver"
import PanelHeader from "~/components/ui/Panels/PanelHeader"
import { serializeVariant } from "~/components/ui/ProductHeader/_data/serialize-variant"
import SquareCta from "~/components/ui/SquareCta"
import { useCartEdito } from "~/providers/CartProvider"
import type { CartLineItemPayload, TrackingEvents } from "~/providers/GTMTrackingProvider/constants"
import { useGlobalTrackingProps } from "~/providers/GTMTrackingProvider/hooks/use-get-tracking-globals"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useInternalRedirects } from "~/providers/InternalRedirectsProvider/index.client"
import { Panel, usePanel } from "~/managers/PanelManager"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

export type CartPanelProps = PropsWithClassName<{}>

const defaultTotals = { totalPrice: 0, totalDiscount: 0 }

export type UnlikelyCartLine = UnlikelyCart["lines"][number]

function CartPanel({}: CartPanelProps) {
  const t = useTranslate()

  const { data: customer } = useGetCustomer()
  const locale = useLocale()
  const wrapperRef = createRef<HTMLDivElement>()
  const { cartPayload, cartItemsNumber } = useGetCart()
  const router = useRouter()
  const pathname = usePathname()
  const { removeCurrent } = usePanel()
  const { sendEvent } = useTracking()
  const isOpen = useRef(false)
  const hasItems = cartItemsNumber > 0
  const currency = getCurrency(getProcessedLocale(locale))
  const globalTrackingProps = useGlobalTrackingProps()
  const { completeTheLookBackground, checkoutRequiresLogin } = useCartEdito()

  const algoliaIndexName = getIndexName({ locale: locale as I18nLocale })
  const algoliaQueryId = useAlgoliaQueryId()
  const algoliaUserToken = Cookies?.get(COOKIE_KEYS.SHOPIFY_USER_ID) ?? null

  const onCartLineClick = (line: UnlikelyCartLine) => {
    if (!line?.merchandise?.product?.handle) return
    const path = getPath(locale, "products/:slug", { slug: line?.merchandise?.product?.handle })
    if (path === pathname) return removeCurrent()
    router.push(path)
  }
  const { mutate: handleRedirectToMultipassUrl, isLoading } = useGetMultipassCheckoutUrl()
  const { mutate: handleDraftOrderConfirmation, isLoading: isLoadingDraftOrder } = useGetDraftOrderUrl({
    onSuccess: (data) => {
      if (data?.urlToRedirect) {
        handleRedirectToMultipassUrl({ url: data?.urlToRedirect, locale })
      }
    },
  })

  const { mutate: updateCartCustomAttribute, isLoading: isLoadingCustomAttributes } = useUpdateCartAttributes()

  const { data: completeTheLook, isLoading: isLoadingCompleteTheLook } = useCompleteTheLookSliderProductCards()

  const processedProductCards = completeTheLook?.items?.filter(
    (item) =>
      !item?.product?.variants?.some((variant) =>
        cartPayload?.lines?.some((line) => line?.merchandise?.id === variant?.id)
      )
  )

  const { totalPrice, totalDiscount } =
    cartPayload?.lines?.reduce((acc, item) => {
      if (!item) return acc

      const foundVariant = serializeVariant(item.merchandise, locale)
      const { processedPrice, processedCompareAtPrice } = processVpPrice({
        price: foundVariant?.price,
        compareAtPrice: foundVariant?.compareAtPrice,
        vpPrice: foundVariant?.vpPrice,
        customer,
      })

      const priceAmount = processedPrice?.amount ?? 0
      const compareAtPriceAmount = processedCompareAtPrice?.amount ?? 0
      const { totalPrice, totalDiscount } = acc
      return {
        totalPrice: totalPrice + priceAmount * (item.quantity ?? 0),
        totalDiscount:
          totalDiscount + (compareAtPriceAmount ? (compareAtPriceAmount - priceAmount) * (item.quantity ?? 0) : 0),
      }
    }, defaultTotals) ?? defaultTotals

  useEffect(() => {
    if (!cartPayload || isOpen.current) return
    sendCartEvent("view_cart", cartPayload, cartPayload.lines)
  }, [cartPayload])

  const updateAlgoliaCartAttributes = (onSuccessCallback: () => void) => {
    if (cartPayload) {
      const algoliaAttributes = {
        indexName: algoliaIndexName,
        userToken: algoliaUserToken,
        queryId: algoliaQueryId,
        ...(customer?.email ? { userAuthenticatedToken: sha256(customer.email) } : {}),
      }

      updateCartCustomAttribute(
        {
          cartId: cartPayload.id,
          attributes: [
            ...cartPayload.attributes,
            algoliaIndexName && algoliaUserToken
              ? {
                  key: CHECKOUT_CUSTOM_ATTRIBUTES.CHECKOUT_ALGOLIA_CONFIG,
                  value: JSON.stringify(algoliaAttributes),
                }
              : undefined,
          ]?.filter(Boolean),
        },
        {
          onSuccess: onSuccessCallback,
        }
      )
    }
  }

  const [redirects] = useInternalRedirects()
  const handleCartValidation = () => {
    updateAlgoliaCartAttributes(() => {
      if (checkoutRequiresLogin) {
        router.push(`${redirects?.login?.href}?checkout_url=${cartPayload?.checkoutUrl}`)
      } else {
        cartPayload?.checkoutUrl && handleRedirectToMultipassUrl({ url: cartPayload.checkoutUrl, locale })
      }
    })
  }

  const handleDraftOrderValidation = () => {
    updateAlgoliaCartAttributes(() => {
      handleDraftOrderConfirmation()
    })
  }

  const sendCartEvent = (
    eventType: keyof Pick<TrackingEvents, "view_cart" | "remove_from_cart">,
    cart: UnlikelyCart,
    items: UnlikelyCart["lines"]
  ) => {
    isOpen.current = true

    try {
      sendEvent(eventType, {
        ecommerce: {
          ...globalTrackingProps,
          value: cart?.cost?.totalAmount?.amount ?? 0,
          items: items.map((lineItem) => {
            const trackingData = getProductCustomAttributesValue(lineItem.attributes, "TRACKING_DATA")?.value ?? ""
            const parsedTrackingData = JSON.parse(trackingData) as CartLineItemPayload
            return {
              ...parsedTrackingData,
              quantity: lineItem.quantity ?? 0,
            }
          }),
        },
      })
    } catch {}
  }
  const lineItems = cartPayload?.lines?.map((line) => (
    <LineItem key={line.id} onClick={() => onCartLineClick(line)} sendCartEvent={sendCartEvent} line={line} />
  ))

  return (
    cartPayload && (
      <>
        <Panel zIndex={70} clickOutsideRef={wrapperRef}>
          <div ref={wrapperRef} className={clsx(css.CartPanel)}>
            <PanelHeader
              title={
                <>
                  {t("cart_title")} {hasItems && `(${cartItemsNumber.toString().padStart(2, "0")})`}
                </>
              }
            />
            <div className={clsx(css.body({ hasItems }))}>
              <div className={clsx(css.bodyInner)}>
                {hasItems && (
                  <div
                    className={css.slider}
                    {...(completeTheLookBackground ? { style: { backgroundColor: completeTheLookBackground } } : {})}
                  >
                    <p className={css.header}>{completeTheLook?.title}</p>
                    <CompleteTheLookSlider isLoading={isLoadingCompleteTheLook} items={processedProductCards ?? []} />
                  </div>
                )}
                <div className={css.lineItems}>
                  {!hasItems && (
                    <div className={css.emptyCart}>
                      <div>{t("cart_continue_shopping")}</div>

                      <InlineCta className={css.continueShopping} withDefaultLine onClick={removeCurrent}>
                        {t("cart_continue_shopping")}
                      </InlineCta>
                    </div>
                  )}
                  {hasItems && <div className={css.lineItemsList}>{lineItems}</div>}
                </div>
              </div>
            </div>

            <div className={clsx(css.footer({ hasItems }))}>
              {hasItems && (
                <div className={clsx(css.footerContainer({ hasItems, withTotalDiscount: Boolean(totalDiscount) }))}>
                  <TotalPrice
                    className={clsx(css.totalPrice)}
                    totalDiscount={totalDiscount ? { currencyCode: currency, amount: totalDiscount } : undefined}
                  />
                  {customer && (
                    <SquareCta
                      theme={"backgroundBlack"}
                      fill
                      onClick={handleDraftOrderValidation}
                      isLoading={isLoading || isLoadingDraftOrder || isLoadingCustomAttributes}
                      size="big"
                    >
                      {`${t("cart_go_checkout")} - ${serializePrice(locale, currency, totalPrice)}`}
                    </SquareCta>
                  )}
                  {!customer && (
                    <SquareCta
                      theme={"backgroundBlack"}
                      fill
                      onClick={handleCartValidation}
                      isLoading={isLoading || isLoadingCustomAttributes}
                      size="big"
                    >
                      {`${t("cart_go_checkout")} - ${serializePrice(locale, currency, totalPrice)}`}
                    </SquareCta>
                  )}
                </div>
              )}
              <ReinsurranceSlider className={clsx(css.reinssuranceSlider)} />
            </div>
          </div>
        </Panel>
      </>
    )
  )
}

export default CartPanel
