"use client"

import { useRef } from "react"
import { clsx } from "clsx"

import Slider from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import { CompleteTheLookCard, type CardProps } from "~/components/ui/CartPanel/CompleteTheLookSlider/Card"
import { CompleteTheLookCardSkeleton } from "~/components/ui/CartPanel/CompleteTheLookSlider/Card/skeleton"
import { usePanel } from "~/managers/PanelManager"

import * as css from "./styles.css"

export type CompleteTheLookSliderProps = PropsWithClassName<{ isLoading?: boolean; items: CardProps[] }>

export function CompleteTheLookSlider({ className, isLoading, items }: CompleteTheLookSliderProps) {
  const { removeCurrent } = usePanel()
  const customSliderRef = useRef<HTMLDivElement>(null)

  if (!items?.length && !isLoading) return null

  if (isLoading) {
    return (
      <div className={clsx(className, css.CompleteTheLookSlider)} ref={customSliderRef}>
        <Slider className={css.slider} containerClassName={css.sliderContainer} snap customSliderRef={customSliderRef}>
          {Array.from({ length: 3 })?.map((_, index) => {
            return <CompleteTheLookCardSkeleton key={index} />
          })}
        </Slider>
      </div>
    )
  }

  return (
    <div className={clsx(className, css.CompleteTheLookSlider)} ref={customSliderRef}>
      <Slider className={css.slider} containerClassName={css.sliderContainer} snap customSliderRef={customSliderRef}>
        {(items ?? [])?.map((card) => {
          return (
            <CompleteTheLookCard key={card.product.id} className={clsx(css.card)} {...card} onClick={removeCurrent} />
          )
        })}
      </Slider>
    </div>
  )
}
